import { pauseAllMedia, qsOptional, qsRequired, targetClosestOptional } from '@/scripts/core/global'
import { type DeferredMedia } from '@/scripts/theme/deferred-media'
import { removeTrapFocus, trapFocus } from '@/scripts/core/global'
import { UcoastEl } from '@/scripts/core/UcoastEl'

export class ModalDialog extends UcoastEl {
	static htmlSelector = 'modal-dialog'
	moved: boolean = false
	openedBy?: HTMLElement
	preloaded = false
	modalId?: string
	preloadEl?: HTMLElement
	constructor() {
		super()
		this.querySelector('[id^="ModalClose-"]')?.addEventListener('click', this.hide.bind(this, false))
		this.addEventListener('keyup', (event) => {
			if (event?.code?.toUpperCase() === 'ESCAPE') this.hide()
		})
		if (this.classList.contains('media-modal')) {
			this.addEventListener('pointerup', (event) => {
				if (event.pointerType === 'mouse' && !targetClosestOptional(event, 'deferred-media, product-model'))
					this.hide()
			})
		} else {
			this.addEventListener('click', (event) => {
				if (event.target === this) this.hide()
			})
		}
		this.modalId = this.getAttribute('data-uc-modal-id') ?? undefined
		if (this.modalId) {
			this.preloadEl = qsRequired(`[data-uc-preload-on-hover-id="${this.modalId}"]`)
			this.preloadEl.addEventListener('mouseenter', this.preload.bind(this))
		}
	}

	override connectedCallback() {
		if (this.moved) return
		this.moved = true
		document.body.appendChild(this)
	}

	preload(event: Event) {
		if (this.preloaded === true || event instanceof TouchEvent) {
			return
		}

		const popup = qsOptional<DeferredMedia>('.template-popup', this)
		if (popup) popup.loadContent()
		this.preloaded = true
	}

	show(opener?: HTMLElement, mediaId?: string) {
		this.openedBy = opener
		if (!this.preloaded) {
			const popup = qsOptional<DeferredMedia>('.template-popup', this)
			if (popup) popup.loadContent()
		}
		this.setAttribute('open', '')
		trapFocus(this, qsRequired('[role="dialog"]', this))
		pauseAllMedia()
		if (mediaId) {
			const selectedMedia = qsRequired(`[data-media-id="${mediaId}"]`, this)
			selectedMedia.scrollIntoView({block: 'start', inline: 'nearest'});
			console.log({ selectedMedia })
		}
	}

	hide() {
		document.body.dispatchEvent(new CustomEvent('modalClosed'))
		this.removeAttribute('open')
		removeTrapFocus(this.openedBy)
		pauseAllMedia()
		this.preloaded = false
	}
}
